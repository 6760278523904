import Head from "next/head";
import { BaseLayout } from "../layouts/base";
import { Container, Heading, Grid } from 'theme-ui';
import Link from 'next/link';

interface NotFoundProps {
    message?: string,
}

export const NotFound = ({message}: NotFoundProps) => (
    <BaseLayout>
      <Head>
        <title>stránka nenalezena</title>
        <meta name="description" content="Aplikace Zrozená k pohybu" />
      </Head>

      <Container sx={{ height: '90vh' }}>
        <Grid sx={{ placeContent: 'center', placeItems: 'center', height: '100%' }}>
          <Heading as="h1">{message || 'Tato stránka neexistuje'}</Heading>

          <Link href="/">Zkuste to z hlavní strany</Link>
        </Grid>
      </Container>
    </BaseLayout>
)
