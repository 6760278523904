import type { NextPage } from 'next'
import { NotFound } from '../components/NotFound'

const Home: NextPage = () => {
  return (
    <NotFound></NotFound>
  )
}

export default Home
